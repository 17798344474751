/* eslint-disable import/no-cycle */
import { AxiosInstance, AxiosError } from 'axios'
import {
	GetAvailableRsTimeSlotsParams,
	GetCosmeticsDataParams,
	GetSalonReviewsDataParams,
	GetSalonsDataForMapParams,
	GetSalonsDataParams,
	GetSalonsFilterCountsParams,
	AvailableRsTimeSlotsResponse,
	CategoriesResponse,
	CategoryResponse,
	ConfigResponse,
	CosmeticsResponse,
	GetSalonsFilterCountResponse,
	LanguagesResponse,
	MapPointsResponse,
	SalonResponse,
	SalonReviewsResponse,
	SalonServicesResponse,
	SalonsFilterResponse,
	SalonsResponse,
	CityByPlaceId,
	BannersResponse,
	BannersParams
} from '../types/types'
import { SALON_TYPE, SORTING_OPTION } from '../utils/enums'
import { getReq } from '../utils/request'

/*
We display salons in 3 or 2 or 1 column grid,
so we need number divisible by 3 a 2 in order to
avoid "missing" salons on the end of the grid -
base number is thus 12.
Update: we need to show banner at 4th place in grid,
that's why we need to fetch only 11 salons per page.
*/
export const SALONS_PER_PAGE = 11

const getSalonsDataParamsForRequest = (query: GetSalonsDataParams) => {
	const {
		page,
		openingHoursStatus,
		latMy,
		lonMy,
		categoryIDs,
		orderBy,
		exactRating,
		languageIDs,
		hasAvailableReservationSystem: hasAvailableReservationSystemParam,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		serviceTotalPriceCurrencyCode
	} = query

	let createType: SALON_TYPE | undefined
	let order: string | undefined
	let hasAvailableReservationSystem: boolean | undefined = hasAvailableReservationSystemParam || undefined

	if (!orderBy || orderBy === SORTING_OPTION.RECOMMENDED) {
		createType = SALON_TYPE.NON_BASIC
		order = 'distance:asc'
		hasAvailableReservationSystem = true
	} else if (orderBy === SORTING_OPTION.CLOSEST) {
		order = 'distance:asc'
	} else if (orderBy === SORTING_OPTION.BEST_RATED) {
		order = 'rating:desc'
	} else if (orderBy === SORTING_OPTION.CHEAPEST) {
		order = 'priceLevel:asc'
	} else if (orderBy === SORTING_OPTION.MOST_EXPENSIVE) {
		order = 'priceLevel:desc'
	}

	const queryParams = {
		page,
		openingHoursStatus,
		latMy,
		lonMy,
		categoryIDs,
		createType,
		order,
		exactRating,
		limit: SALONS_PER_PAGE,
		hasAvailableReservationSystem,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		serviceTotalPriceCurrencyCode,
		languageIDs
	}
	return queryParams
}

const getSalonsData = async (query: GetSalonsDataParams, client: AxiosInstance): Promise<SalonsResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/', { params: { query: getSalonsDataParamsForRequest(query) }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getSalonsFilterCounts = async (query: GetSalonsFilterCountsParams, client: AxiosInstance): Promise<GetSalonsFilterCountResponse | undefined> => {
	const { serviceTotalPriceCurrencyCode } = query
	const {
		openingHoursStatus,
		latMy,
		lonMy,
		categoryIDs,
		createType,
		exactRating,
		hasAvailableReservationSystem,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		languageIDs
	} = getSalonsDataParamsForRequest(query)

	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/counts', {
			params: {
				query: {
					openingHoursStatus,
					latMy,
					lonMy,
					categoryIDs,
					createType,
					exactRating,
					hasAvailableReservationSystem,
					serviceTotalPriceTo,
					serviceTotalPriceFrom,
					serviceTotalPriceCurrencyCode,
					languageIDs
				}
			},
			reqBody: {},
			customConfig: { allowAbort: true }
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getSalonsDataForMap = async (query: GetSalonsDataForMapParams, client: AxiosInstance): Promise<MapPointsResponse | undefined> => {
	const {
		openingHoursStatus,
		latMy,
		lonMy,
		categoryIDs,
		createType,
		exactRating,
		hasAvailableReservationSystem,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		serviceTotalPriceCurrencyCode,
		languageIDs
	} = getSalonsDataParamsForRequest(query)

	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/map', {
			params: {
				query: {
					openingHoursStatus,
					categoryIDs,
					exactRating,
					createType,
					hasAvailableReservationSystem,
					serviceTotalPriceFrom,
					serviceTotalPriceTo,
					serviceTotalPriceCurrencyCode,
					languageIDs,
					latMy,
					lonMy,
					latNW: query.latNW,
					lonNW: query.lonNW,
					latSE: query.latSE,
					lonSE: query.lonSE
				}
			},
			reqBody: {}
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getSalonsFilterData = async (search: string, client: AxiosInstance): Promise<SalonsFilterResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/', { params: { query: { search } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getSalonData = async (seoSlugName: string, client: AxiosInstance): Promise<SalonResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/by-seo-slugname/{seoSlugName}', { params: { path: { seoSlugName } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return { status: (error as AxiosError)?.response?.status }
	}
}

const getSalonServicesData = async (salonID: string, client: AxiosInstance): Promise<SalonServicesResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/{salonID}/services', { params: { path: { salonID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const REVIEWS_LIMIT = 3
const getSalonReviewsData = async (salonID: string, params: GetSalonReviewsDataParams, client: AxiosInstance): Promise<SalonReviewsResponse | undefined> => {
	try {
		const queryParams = {
			page: params.page,
			limit: REVIEWS_LIMIT
		}
		const { data } = await getReq(client, '/api/b2c/web/salons/{salonID}/reviews/', { params: { query: queryParams, path: { salonID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getCategoriesData = async (client: AxiosInstance): Promise<CategoriesResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/categories/', { params: {}, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getCategoryData = async (categoryID: string, client: AxiosInstance): Promise<CategoryResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/categories/{categoryID}', { params: { path: { categoryID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getConfigData = async (client: AxiosInstance): Promise<ConfigResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/config/', { params: {}, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getCosmeticsData = async (query: GetCosmeticsDataParams, client: AxiosInstance): Promise<CosmeticsResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/cosmetics/', { params: { query }, reqBody: {}, customConfig: { allowAbort: true } })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getLanguagesData = async (client: AxiosInstance): Promise<LanguagesResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/languages/', { params: {}, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getAvailableReservationTimeSlotsData = async (
	query: GetAvailableRsTimeSlotsParams,
	client: AxiosInstance
): Promise<AvailableRsTimeSlotsResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/available-reservation-time-slots', { params: { query }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getCityNameByPlaceId = async (placeId: string, client: AxiosInstance): Promise<CityByPlaceId | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/cities/{placeID}', { params: { path: { placeID: placeId } }, reqBody: {} })
		return data.city
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getBanners = async (shopID: BannersParams['shopID'], client: AxiosInstance): Promise<BannersResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/banners/', { params: { query: { shopID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const loadApiEndpoints = (client: AxiosInstance) => {
	return {
		getSalonData: (seoSlugName: string) => getSalonData(seoSlugName, client),
		getSalonsDataForMap: (params: GetSalonsDataForMapParams) => getSalonsDataForMap(params, client),
		getSalonsFilterCounts: (params: GetSalonsFilterCountsParams) => getSalonsFilterCounts(params, client),
		getSalonServicesData: (salonID: string) => getSalonServicesData(salonID, client),
		getSalonReviewsData: (salonID: string, params: GetSalonReviewsDataParams) => getSalonReviewsData(salonID, params, client),
		getSalonsData: (params: GetSalonsDataParams) => getSalonsData(params, client),
		getCategoriesData: () => getCategoriesData(client),
		getCategoryData: (categoryID: string) => getCategoryData(categoryID, client),
		getSalonsFilterData: (search: string) => getSalonsFilterData(search, client),
		getConfigData: () => getConfigData(client),
		getCosmeticsData: (params: GetCosmeticsDataParams) => getCosmeticsData(params, client),
		getLanguagesData: () => getLanguagesData(client),
		getAvailableReservationTimeSlotsData: (params: GetAvailableRsTimeSlotsParams) => getAvailableReservationTimeSlotsData(params, client),
		getCityNameByPlaceId: (placeId: string) => getCityNameByPlaceId(placeId, client),
		getBanners: (shopID: BannersParams['shopID']) => getBanners(shopID, client)
	}
}

export default loadApiEndpoints

export type ApiClient = ReturnType<typeof loadApiEndpoints>
