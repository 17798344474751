/* eslint-disable global-require */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Slider from 'react-slick'
import { IconRegularChevronLeft, IconRegularChevronRight, IconSolidInfo, Modal } from '@notino/react-styleguide'

// types
import { AvailableLanguagesType, BannersResponse } from '../../../../types/types'

// utils
import { SALONS_PAGE_BANNER_SETTINGS } from '../../../../utils/enums'

// styles
import * as SC from './SalonsPageBannerStyles'

type SalonsPageBannerProps = {
	assetsPath: string
	bannersData: BannersResponse | undefined
}

type SlideContent = {
	key: string
	desktopImage: string
	mobileImage: string
	backgroundColor?: string
	backgroundImage?: string
	urlLink?: string
	infoText?: string
}

type ArrowProps = {
	onClick?: () => void
}

type InfoBannerData = {
	text: string
}

const { DEFAULT_BACKGROUND_COLOR, MOBILE_BANNER_WIDTH } = SALONS_PAGE_BANNER_SETTINGS

const ArrowLeft = (props: ArrowProps) => {
	const { onClick } = props
	return (
		<SC.ArrowLeftButton type={'button'} onClick={onClick}>
			<IconRegularChevronLeft color={'icon.primary'} width={'2rem'} height={'2rem'} />
			<IconRegularChevronLeft color={'icon.inverse'} width={'2rem'} height={'2rem'} />
		</SC.ArrowLeftButton>
	)
}

const ArrowRight = (props: ArrowProps) => {
	const { onClick } = props

	return (
		<SC.ArrowRightButton type={'button'} onClick={onClick}>
			<IconRegularChevronRight color={'icon.primary'} width={'2rem'} height={'2rem'} />
			<IconRegularChevronRight color={'icon.inverse'} width={'2rem'} height={'2rem'} />
		</SC.ArrowRightButton>
	)
}

// component
const SalonsPageBanner = (props: SalonsPageBannerProps) => {
	const { assetsPath, bannersData } = props
	const intl = useIntl()
	const locale = intl.locale as AvailableLanguagesType

	const [infoBannerData, setInfoBannerData] = useState<InfoBannerData | null>(null)

	let slides: SlideContent[] = []

	if (!bannersData || bannersData.banners.length === 0) {
		const backgroundImage = `${assetsPath}/${require('../../../../assets/images/top-banner-background.jpg')}`
		let desktopImage = ''
		let mobileImage = ''
		let urlLink = ''

		switch (locale) {
			case 'ro':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/top-banner-ro-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/top-banner-ro-mobile.jpg')}`
				urlLink = 'https://www.notino.ro/salons-promo/'
				break
			case 'bg':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/top-banner-bg-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/top-banner-bg-mobile.jpg')}`
				urlLink = 'https://www.notino.bg/salons-promo/'
				break
			case 'hu':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/top-banner-hu-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/top-banner-hu-mobile.jpg')}`
				urlLink = 'https://www.notino.hu/salons-promo/'
				break
			case 'sk':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/top-banner-sk-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/top-banner-sk-mobile.jpg')}`
				urlLink = 'https://www.notino.sk/salons-promo/'
				break
			case 'cs':
			default:
				desktopImage = `${assetsPath}/${require('../../../../assets/images/top-banner-cs-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/top-banner-cs-mobile.jpg')}`
				urlLink = 'https://www.notino.cz/salons-promo/'
				break
		}

		const slide: SlideContent = {
			key: String(1),
			desktopImage,
			mobileImage,
			backgroundImage,
			urlLink
		}

		slides = [slide]
	} else {
		slides = bannersData.banners
			.sort((a, b) => a.orderIndex - b.orderIndex)
			.map((banner) => {
				const { id, desktopImage, mobileImage, urlLink, infoText, backgroundColor, backgroundFile } = banner
				const backgroundImage = backgroundFile?.original
				let resolvedBackgroundColor

				if (backgroundImage) {
					resolvedBackgroundColor = DEFAULT_BACKGROUND_COLOR
				} else {
					resolvedBackgroundColor = backgroundColor || DEFAULT_BACKGROUND_COLOR
				}

				return {
					key: id,
					desktopImage: desktopImage.original,
					mobileImage: mobileImage.original,
					urlLink,
					backgroundColor: resolvedBackgroundColor,
					backgroundImage,
					infoText
				}
			})
	}

	return (
		<>
			<SC.SalonsPageBannerContainer>
				<SC.SliderWrapper>
					<Slider
						infinite
						adaptiveHeight={false}
						accessibility
						speed={500}
						fade
						waitForAnimate={false}
						slidesToScroll={1}
						slidesToShow={1}
						autoplay
						autoplaySpeed={5000}
						arrows={slides.length > 1}
						prevArrow={<ArrowLeft />}
						nextArrow={<ArrowRight />}
					>
						{slides.map((slide) => {
							const picture = (
								<SC.Picture>
									<source srcSet={slide.desktopImage} media={`(min-width: ${MOBILE_BANNER_WIDTH}px)`} />
									<source srcSet={slide.mobileImage} />
									<img src={slide.desktopImage} width={1035} height={340} alt='' />
								</SC.Picture>
							)

							const slideContent = slide.urlLink ? (
								<SC.SliderLink href={slide.urlLink}>{picture}</SC.SliderLink>
							) : (
								<SC.SliderItem>{picture}</SC.SliderItem>
							)

							return (
								// empty <div> element, because react-slick adds its own inline styles to the first children element
								<div key={slide.key}>
									<SC.BannerBackground
										$backgroundImage={slide.backgroundImage}
										$backgroundColor={slide.backgroundColor || DEFAULT_BACKGROUND_COLOR}
									>
										<SC.MainBannerContainer>
											{slideContent}
											{slide.infoText && (
												<SC.InfoIconButton
													type={'button'}
													onClick={() => {
														if (slide.infoText) {
															setInfoBannerData({ text: slide.infoText })
														}
													}}
												>
													<IconSolidInfo width={'1.25rem'} height={'1.25rem'} color={'icon.tertiary'} />
												</SC.InfoIconButton>
											)}
										</SC.MainBannerContainer>
									</SC.BannerBackground>
								</div>
							)
						})}
					</Slider>
				</SC.SliderWrapper>
			</SC.SalonsPageBannerContainer>
			{infoBannerData && (
				<Modal show onClose={() => setInfoBannerData(null)}>
					<SC.ModalText>{infoBannerData.text}</SC.ModalText>
				</Modal>
			)}
		</>
	)
}

export default SalonsPageBanner
