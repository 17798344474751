import React from 'react'
import * as SC from './TeamMobileStyles'
import { SalonDetail } from '../../../../types/types'
import ElementsSlider from '../../../../components/ElementsSlider/ElementsSlider'

type Props = {
	salonData?: SalonDetail
}

const TeamMobile = ({ salonData }: Props) => {
	return (
		<ElementsSlider gap={'28px'} fading={false}>
			{salonData?.employees.map((employee) => (
				<SC.Employee key={employee.id}>
					<img loading='lazy' alt={employee.firstName} src={employee.image.resizedImages.thumbnail} />
					<SC.EmployeeName>{employee.firstName}</SC.EmployeeName>
				</SC.Employee>
			))}
		</ElementsSlider>
	)
}

export default TeamMobile
