import React from 'react'
import * as SC from './SalonDetailPlaceholderStyles'

const SalonDetailPlaceholder = () => {
	return (
		<SC.PlaceholderWrapper>
			<SC.PlaceholderImage />
			<SC.PlaceholderContent>
				<SC.PlaceholderText />
				<SC.PlaceholderText />
				<SC.PlaceholderText />
			</SC.PlaceholderContent>
		</SC.PlaceholderWrapper>
	)
}

export default SalonDetailPlaceholder
