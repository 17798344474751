import React, { useEffect } from 'react'
import { theme, ThemeProvider } from '@notino/react-styleguide'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import qs from 'query-string'
import { QueryParamProvider } from 'use-query-params'
import styled, { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sk'
import 'dayjs/locale/cs'
import 'dayjs/locale/hu'
import 'dayjs/locale/ro'
import 'dayjs/locale/bg'

import Routes from './routes/routes'

// styles
import GlobalStyles, { AppContainer } from './styles/globalStyles'
import 'overlayscrollbars/styles/overlayscrollbars.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// types
import { ContextProps } from './types/types'

// utils
import LanguageProvider from './utils/languageProvider'
import { DEFAULT_LOCALE, shopsConfig } from './appDefaults'

dayjs.locale(DEFAULT_LOCALE)
dayjs.extend(localeData)
dayjs.extend(weekday)
dayjs.extend(LocalizedFormat)

const AppVersion = styled.div`
	display: none;
`

const shouldForwardProp = (propName: string, target: any) => {
	if (typeof target === 'string') {
		return isPropValid(propName)
	}
	return true
}

const AppLayout: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	useEffect(() => {
		const dayjsLocale = shopsConfig.find((config) => config.lang === props.locale)?.locale || DEFAULT_LOCALE
		dayjs.locale(dayjsLocale)
	}, [props.locale])

	return (
		<StyleSheetManager shouldForwardProp={shouldForwardProp}>
			<QueryParamProvider adapter={ReactRouter6Adapter} options={{ searchStringToObject: qs.parse, objectToSearchString: qs.stringify }}>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<LanguageProvider {...props}>
						<AppContainer>
							<Routes {...props} />
						</AppContainer>
					</LanguageProvider>
				</ThemeProvider>
			</QueryParamProvider>
			<AppVersion id='app_version'>{props.appVersion}</AppVersion>
		</StyleSheetManager>
	)
}

export default AppLayout
