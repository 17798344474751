/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { Button as NotinoButton } from '@notino/react-styleguide'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import { NotinoLabelRegular } from '../../../../styles/helpers'

export const CtaButtonsWrapper = styled.div`
	margin-bottom: 28px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-bottom: 60px;
	}
`

export const Button = styled(NotinoButton)`
	// fix for notino button when using href
	display: flex;
	align-items: center;
	${NotinoLabelRegular};
`
