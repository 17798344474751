import styled from 'styled-components'
import { NotinoBodyMedium } from '../../../../styles/helpers'

export const Employee = styled.div`
	${NotinoBodyMedium};

	width: 80px;
	text-align: center;
	flex-shrink: 0;
	padding-bottom: 4px; /* gap to (potential) horizontal scrollbar */
	display: flex;
	flex-direction: column;
	gap: 8px;

	& > img {
		aspect-ratio: 1;
		object-fit: contain;
		border-radius: 50%;
	}
`

export const EmployeeName = styled.div`
	word-break: break-all;
`
