import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyLarge, resetButtonStyles } from '../../../../styles/helpers'
import { SALONS_PAGE_BANNER_SETTINGS } from '../../../../utils/enums'

const { DEFAULT_BACKGROUND_COLOR, DESKTOP_BANNER_WIDTH, DESKTOP_BANNER_HEIGHT, MOBILE_BANNER_HEIGHT, MOBILE_BANNER_WIDTH } = SALONS_PAGE_BANNER_SETTINGS

export const SalonsPageBannerContainer = styled.div`
	width: 100%;
	padding-bottom: ${(MOBILE_BANNER_HEIGHT / MOBILE_BANNER_WIDTH) * 100}%;
	height: 0;
	overflow: hidden;
	position: relative;
	background: ${DEFAULT_BACKGROUND_COLOR};

	@media (min-width: ${MOBILE_BANNER_WIDTH}px) {
		padding-bottom: ${(DESKTOP_BANNER_HEIGHT / DESKTOP_BANNER_WIDTH) * 100}%;
	}

	@media (min-width: ${DESKTOP_BANNER_WIDTH}px) {
		padding-bottom: ${DESKTOP_BANNER_HEIGHT}px;
	}
`

export const SliderWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.slick-slider {
		height: 100%;

		.slick-list {
			height: 100% !important;

			.slick-track {
				height: 100%;
				display: flex;
				margin-left: initial;
				margin-right: initial;

				.slick-slide {
					height: auto;
					display: flex;

					> div {
						width: 100%;
						display: flex;
					}
				}
			}
		}
	}
`

export const BannerBackground = styled.div<{ $backgroundImage?: string; $backgroundColor: string }>`
	width: 100%;
	height: 100%;
	background-size: auto 100%;
	background-position: center;
	background-color: ${({ $backgroundColor }) => $backgroundColor};
	display: flex;
	justify-content: center;

	@media (min-width: ${DESKTOP_BANNER_WIDTH}px) {
		background-image: ${({ $backgroundImage }) => ($backgroundImage ? `url("${$backgroundImage}")` : 'none')};
	}
`

export const MainBannerContainer = styled.div`
	flex-shrink: 0;
	width: 100%;
	max-width: ${DESKTOP_BANNER_WIDTH}px;
	position: relative;
`

export const SliderLink = styled.a`
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-decoration: none;
	display: block;
`

export const SliderItem = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const Picture = styled.picture`
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`

export const InfoIconButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${theme.color.background.primary};
	border-radius: 100%;
	height: 1.125rem;
	width: 1.125rem;
	bottom: 0px;
	right: 0px;
	margin: 0.5rem;
`

const ArrowButtonCommonStyles = css`
	${resetButtonStyles};
	position: absolute;
	top: 50%;
	display: none;
	color: transparent;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	z-index: 10;
	transform: translate(0, -50%);

	svg:last-child {
		position: absolute;
	}

	@media (min-width: ${MOBILE_BANNER_WIDTH}px) {
		display: flex;
	}
`

export const ArrowLeftButton = styled.button`
	${ArrowButtonCommonStyles};
	left: 0;

	svg:last-child {
		left: -0.125rem;
	}

	@media (min-width: ${DESKTOP_BANNER_WIDTH}px) {
		left: 50%;
		transform: translate(-${DESKTOP_BANNER_WIDTH / 2}px, -50%);
	}
`

export const ArrowRightButton = styled.button`
	${ArrowButtonCommonStyles};
	right: 0;

	svg:last-child {
		right: -0.125rem;
	}

	@media (min-width: ${DESKTOP_BANNER_WIDTH}px) {
		right: 50%;
		transform: translate(${DESKTOP_BANNER_WIDTH / 2}px, -50%);
	}
`

export const ModalText = styled.p`
	${NotinoBodyLarge};
	overflow: hidden;
	white-space: pre-wrap;
	margin-top: 24px;
`
