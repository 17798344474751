import { useCallback, useEffect, useState } from 'react'

const SCROLL_DISTANCE = 200
const IS_SCROLLED_BUFFER = 5

const useScrolledElement = (element: HTMLElement | null) => {
	const [isScrolledLeft, setIsScrolledLeft] = useState(false)
	const [isScrolledRight, setIsScrolledRight] = useState(false)

	useEffect(() => {
		if (!element) return undefined

		const getIsScrolledLeft = () => {
			return element.scrollLeft > IS_SCROLLED_BUFFER
		}

		const getIsScrolledRight = () => {
			return !(element.scrollLeft + element.clientWidth > element.scrollWidth - IS_SCROLLED_BUFFER)
		}

		setIsScrolledLeft(getIsScrolledLeft())
		setIsScrolledRight(getIsScrolledRight())

		const handleScroll = () => {
			setIsScrolledLeft(getIsScrolledLeft())
			setIsScrolledRight(getIsScrolledRight())
		}

		element.addEventListener('scroll', handleScroll)

		return () => {
			element?.removeEventListener('scroll', handleScroll)
		}
	}, [element])

	const scrollLeft = useCallback(() => element?.scrollBy(-SCROLL_DISTANCE, 0), [element])
	const scrollRight = useCallback(() => element?.scrollBy(SCROLL_DISTANCE, 0), [element])

	return { isScrolledLeft, isScrolledRight, scrollLeft, scrollRight } as const
}

export default useScrolledElement
