import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'

// utils
import { isElementOverflownX, SCROLLBAR_OPTIONS } from '../../utils/helper'

// hooks
import useScrolledElement from '../../hooks/useScrolledElement'

// styles
import * as SC from './ElementsSliderStyles'

// props
type Props = PropsWithChildren<{
	initScrolledElementID?: string
	initScrollBehavior?: ScrollBehavior
	fading?: boolean
	gap?: string
}>

// component
const ElementsSlider = (props: Props) => {
	const { children, initScrolledElementID, fading = true, initScrollBehavior = 'instant', gap = '0px' } = props

	const [isOverflown, setIsOverflown] = useState(false)

	const scrollElementRef = useRef<OverlayScrollbarsComponentRef<'div'>>(null)
	const initElementScrolledRef = useRef(false)

	const { isScrolledLeft, isScrolledRight } = useScrolledElement(fading ? (scrollElementRef.current?.osInstance()?.elements().viewport ?? null) : null)

	useEffect(() => {
		if (!fading) {
			return undefined
		}
		const checkOverflow = () => {
			if (scrollElementRef.current) {
				const viewport = scrollElementRef.current?.osInstance()?.elements()?.viewport

				if (viewport) {
					setIsOverflown(isElementOverflownX(viewport))
				}
			}
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)

		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [fading])

	useEffect(() => {
		/**
		 * scroll slider to the provided element ID on component init
		 */
		const container = scrollElementRef.current?.osInstance()?.elements()?.viewport

		if (container && initScrolledElementID && !initElementScrolledRef.current) {
			const targetElement = document.getElementById(initScrolledElementID)

			if (targetElement) {
				const containerRect = container.getBoundingClientRect()
				const targetElementRect = targetElement.getBoundingClientRect()

				// Calculate the scroll position
				const targetElementLeft = targetElementRect.left - containerRect.left
				const targetElementRight = targetElementRect.right - containerRect.left
				const containerWidth = containerRect.width

				// Determine if the targetElement is out of view
				if (targetElementLeft < 0 || targetElementRight > containerWidth) {
					// Calculate the new scroll position to center the targetElement
					const newScrollLeft = targetElement.offsetLeft - container.clientWidth / 2 + targetElement.clientWidth / 2

					// Ensure the scroll position is within the bounds of the scrollable area
					const maxScrollLeft = container.scrollWidth - container.clientWidth
					const boundedScrollLeft = Math.min(Math.max(newScrollLeft, 0), maxScrollLeft)

					// Scroll the container
					container.scrollTo({
						left: boundedScrollLeft,
						behavior: initScrollBehavior
					})
				}
			}
		}
	}, [initScrolledElementID, initScrollBehavior])

	return (
		<SC.ElementsSliderWrapper>
			{fading && isOverflown && isScrolledLeft && <SC.FadeLeft />}
			<SC.ElementsSlider ref={scrollElementRef as any} options={SCROLLBAR_OPTIONS}>
				<SC.ElementsSliderContent $gap={gap}>{children}</SC.ElementsSliderContent>
			</SC.ElementsSlider>
			{fading && isOverflown && isScrolledRight && <SC.FadeRight />}
		</SC.ElementsSliderWrapper>
	)
}

export default ElementsSlider
