import { IconSolidStar } from '@notino/react-styleguide'
import React from 'react'
import { useIntl } from 'react-intl'
import SalonImage from '../SalonImage/SalonImage'
import useMessages from '../../../../hooks/useMessages'
import Paths from '../../../../routes/paths'
import SalonDetailPlaceholder from '../SalonDetailPlaceholder/SalonDetailPlaceholder'

// types
import { ContextProps, Salon } from '../../../../types/types'

// assets
import ClockIcon from '../../../../assets/icons/ClockIcon'

// utils
import CalendarIcon from '../../../../assets/icons/CalendarIcon2'
import PhoneIcon from '../../../../assets/icons/PhoneIcon'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { getSelectItemEvent } from '../../../../utils/dataLayerEvents'
import { AVAILABLE_RESERVATION_TYPE } from '../../../../utils/enums'
import { formatUrlSlug } from '../../../../utils/helper'

// styles
import * as SC from './SalonDetailStyles'

// types
type SalonDetailProps = ContextProps & {
	salon: Salon
	isLoading: boolean
	listPosition?: number
	currentCategorySlug?: string
}

type AddressType = Salon['address']
// type PriceLevelType = ISalon['priceLevel'] // will be used in the future, commented out for now

// helpers
const getAddress = (address: AddressType) => `${address?.street} ${address?.streetNumber}, ${address?.city}`

// component
const SalonDetail: React.FC<React.PropsWithChildren<SalonDetailProps>> = (props) => {
	const { salon, assetsPath, isLoading, listPosition } = props
	const { locale } = useIntl()
	const { messages } = useMessages()

	const itemHref = `${Paths[locale as keyof typeof Paths]['/salons']}${formatUrlSlug(salon.seoSlugName)}`

	if (isLoading) {
		return <SalonDetailPlaceholder />
	}

	const handleItemClick = () => {
		// push to dataLayer
		const event = getSelectItemEvent({ listPosition, salon })
		pushToDataLayer(event)
	}

	return (
		<SC.SalonDetailItem href={itemHref} onClick={handleItemClick}>
			<SC.ImageWrapper>
				{salon.availableReservationType && salon.availableReservationType === AVAILABLE_RESERVATION_TYPE.ONLINE_RESERVATION && (
					<SC.AvailableRSBadge>
						<CalendarIcon />
						{messages?.['Online reservation']}
					</SC.AvailableRSBadge>
				)}
				{salon.availableReservationType && salon.availableReservationType === AVAILABLE_RESERVATION_TYPE.PHONE_RESERVATION && (
					<SC.AvailableRSBadge>
						<PhoneIcon />
						{messages?.['Phone reservation only']}
					</SC.AvailableRSBadge>
				)}
				<SalonImage salon={salon} assetsPath={assetsPath} />
			</SC.ImageWrapper>

			{salon.logo?.resizedImages.small && (
				<SC.LogoWrapper>
					<img loading='lazy' src={salon.logo.resizedImages.thumbnail} alt='salon logo' />
				</SC.LogoWrapper>
			)}

			{salon.rating && (
				<SC.Rating>
					<IconSolidStar width='12' height='12' style={{ marginBottom: '2px' }} color={'icon.highlight'} />
					<span>{salon.rating.toFixed(1)}</span>
				</SC.Rating>
			)}

			{salon.name && <SC.Title>{salon.name}</SC.Title>}

			<SC.Description>
				{salon.address && <SC.Address>{getAddress(salon.address)}</SC.Address>}

				{salon.openingHoursData && (
					<SC.OpeningHours $isOpen={salon.openingHoursData?.isOpen}>
						<ClockIcon />
						<span>
							<SC.OpeningHoursStatus $isOpen={salon.openingHoursData?.isOpen}>
								{salon.openingHoursData.isOpen ? messages?.Open : messages?.Close}
							</SC.OpeningHoursStatus>
							{salon.openingHoursData.message && ` • ${salon.openingHoursData.message}`}
						</span>
					</SC.OpeningHours>
				)}
			</SC.Description>
		</SC.SalonDetailItem>
	)
}

export default SalonDetail
